"use client"
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { UserNav } from './usernav';
import Link from 'next/link';

const linksBeforeLogin = (
  <ul className="menu border-b md:border-none flex justify-end list-reset m-0 w-full md:w-auto">
    <li className="border-t md:border-none">
      <Link
        href="/"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker font-bold"
      >
        Home
      </Link>
    </li>

    <li className="border-t md:border-none">
      <Link
        href="/about"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        About Us
      </Link>
    </li>
    <li className="border-t md:border-none">
      <Link
        href="/jobs/browse"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        Search jobs
      </Link>
    </li>
    <li className="border-t md:border-none">
      <Link
        href="/champions"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        Community champions
      </Link>
    </li>
    <li className="border-t md:border-none">
      <Link
        href="/manage/jobs"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        Post a Referral
      </Link>
    </li>
    <li className="border-t md:border-none">
      <Link
        href="/signup/"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        Sign Up
      </Link>
    </li>

    <li className="border-t md:border-none">
      <Link
        href="/signin/"
        className="block md:inline-block px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        Sign In
      </Link>
    </li>
  </ul>
);

const linksAfterLogin = (user: any) => (
  <ul className="menu border-b md:border-none flex justify-end list-reset m-0 w-full md:w-auto">
    <li className="border-t md:border-none">
      <Link
        href="/jobs/browse"
        className="block md:inline-block px-4 py-3 md:pt-4 no-underline text-grey-darkest hover:text-grey-darker"
      >
        search jobs
      </Link>
    </li>
    <li className="border-t md:border-none">
      <Link
        href="/champions"
        className="block md:inline-block px-4 py-3 md:pt-4 no-underline text-grey-darkest hover:text-grey-darker"
      >
        Community Champions
      </Link>
    </li>
    {user?.role === "jobPoster" &&
      <li className="border-t md:border-none">
        <Link
          href="/manage/jobs"
          className="block md:inline-block px-4 py-3 md:pt-4 no-underline text-grey-darkest hover:text-grey-darker"
        >
          post a referral
        </Link>
      </li>
    }
    <li className="flex items-center border-t md:border-none cursor-pointer">
      <span
        className="flex px-4 py-3 no-underline text-grey-darkest hover:text-grey-darker"
      >
        <UserNav user={user} />
      </span>
    </li>
  </ul>
);

const NavLinks = ({ user, type }: { user?: any, type: "home" | "dashboard" }) => {

  const [open, setOpen] = useState<string>("")

  const pathname = usePathname()

  useEffect(() => {
    console.log(pathname)
    if (open !== "") {
      setOpen("")
    }
  }, [pathname])

  return (
    <div className="w-full" style={{
      zIndex: 20
    }}>
      <nav className="nav bg-transparent flex flex-wrap items-center justify-between px-4">
        <div className="flex flex-no-shrink items-center mr-6 py-3 text-grey-darkest">
          <span className="font-semibold text-xl tracking-tight">51REQS</span>
        </div>

        <div className={"menu-btn " + open} id="menu-btn" />
        <div
          className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
          // htmlFor="menu-btn"
          onClick={() => {
            if (open == "") {
              setOpen("open")
            } else {
              setOpen("")
            }
          }}
        >
          <span className="navicon bg-grey-darkest flex items-center relative" ></span>
        </div>
        {
          type == "home" ? linksBeforeLogin : (user ? linksAfterLogin(user) : linksBeforeLogin)
        }
      </nav>
    </div>
  )
}

export default NavLinks